import { useState } from 'react';
import React from 'react';
import van from "../assets/van.jpg";
import "./Head.scss";

const Head = () => {
  const [active, setActive] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
    setActive(sectionId);
    console.log(active)
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      <div className='head'>
        <div className='head-container'>
          <div className="head-header">
            <h1 className='main-head'>Your Trust,<br></br> Our Foundation</h1>
            <button onClick={() => scrollToSection('contact')} className='btn-getstarted'>get started</button>
          </div>
      
          {imageLoaded ? (
            <div
              className='head-image'
              style={{
                backgroundImage: `url(${van})`,
              }}
            ></div>
          ) : (
            <div className="loading-indicator">
              Loading...
            </div>
          )}
        </div>
      </div>
      <img
        src={van}
        alt="Van"
        onLoad={handleImageLoad}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default Head;
