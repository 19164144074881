import React from 'react';
import { FiMail } from 'react-icons/fi';
import './Top.scss';

function Top() {
  return (
    <div className='top-container'>

      <p><FiMail style={{ color: 'white' }} size={15} /> <a href="mailto:info@calcuttaconstructions.ca">info@calcuttaconstructions.ca</a></p>
    </div>
  );
}

export default Top;
