import React from 'react';
import cr from '../assets/ce.png';
import trained from '../assets/trained.png'
import satisfaction from '../assets/satisfaction.png'
import about from "../assets/about.jpeg"
import './About.scss';
import 'animate.css';

const About = () => {
  return (
   <div className='about1'>
     <h1 className='about-head'>About Us</h1>
    <div className='about'>
      <div className='about-main'>
      
      <p className='about-mainpara animate__animated animate__backInLeft animate__slow'>
  A Greater Vancouver Based Construction Company 
  At <strong>Calcutta Constructions</strong>, we're more than just a <strong>Drywall</strong> company; we're a team of passionate professionals dedicated to transforming spaces with precision, craftsmanship, and a commitment to excellence. With years of industry experience, our journey began with a simple goal: to set new standards of drywall installation and finishing. Today, that commitment remains unwavering as we continue to deliver exceptional services, unrivaled quality, and the utmost customer satisfaction. Discover the difference, where every wall tells a story of expertise and dedication.
</p>
      <img className='about-mainpic animate__animated animate__backInRight animate__slow' src={ about } alt="about" />
      </div>
      
      <div className='about-container animate__animated animate__bounceIn__slow'>
        <div className='about-types'>
          <div className='about-box'>
            <div className='about-icons'>
              <img src={trained} alt="" />
            </div>
            <div className='about-type'>
  <h3>Trained Professionals</h3>
  <p>
    Whether it's a multi-unit commercial project,<b>single-family home, </b>
   <b>Schools</b>, <b>Hospitals</b>,or <b>Courthouses </b> 
     with unique design features, we provide stunning finishes and interior support.
  </p>
</div>

          </div>
          <div className='about-box'>
            <div className='about-icons'>
             <img src={cr} alt="" />
            </div>
            <div className='about-type'>
              <h3>Commercial and Residential Construction</h3>
              <p>
              We specialize in both commercial and residential construction. With our expert team and dedication to quality, 
              we bring your construction dreams to life. Discover excellence in every project, from dream homes to functional commercial spaces. Let's build your future together.
              </p>
            </div>
          </div>
          <div className='about-box'>
            <div className='about-icons'>
              <img src={satisfaction} alt="" />
            </div>
            <div className='about-type'>
              <h3>We Believe in Customer Satisfaction</h3>
              <p>
              Customer satisfaction is at the core of everything we do. We are committed to exceeding your expectations with our services. Your happiness is our success. Choose us for a construction experience that puts your needs first.
              </p>
            </div>
          </div>
        
        </div>
      </div>
    </div>
    </div>
  );
};

export default About;
