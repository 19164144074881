import Head from './components/Head';
import Navhead from './components/Navhead';
import Quote from './components/Quote';
import About from './components/About';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import Maps from './components/Maps';
import Footer from './components/Footer';
import Record from './components/Record';
import CareerPage from './components/CareerPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/careers" element={<CareerPage />} />
        <Route path="/" element={(
          <>
            <Navhead />
            <div id='home'>
              <Head />
            </div>

            <div id='aboutus'>
              <About />
            </div>

            <div id='services'>
              <Services />
            </div>

            <Record />
            <div id='portfolio'>
              <Portfolio />
            </div>
            <Maps />
            <div id='contact'>
              <Quote />
            </div>
            <Footer />
          </>
        )} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
