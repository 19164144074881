import React from 'react';
import { useState } from 'react';
import { FiMail } from 'react-icons/fi';
import { FaMapMarkerAlt } from 'react-icons/fa';
import ScrollToTopButton from './Scroll';
// import { Link } from 'react-router-dom';

import './Footer.scss';
import logo from '../assets/logo1.png';

function Footer() {
  

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
    setActive(sectionId);
  };
  const [active, setActive] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  console.log(active)

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  return (
    <div className='footer'>
      <div className='sb__footer section__padding'>
        <div className='sb__footer-links'>
          <div className='sb__footer-links_div'>
            <img className='logo-port' src={logo} alt="iconport" />
          </div>
          <div className='sb__footer-links_div'>
            <h4>EXPLORE</h4>
            <a onClick={() => scrollToSection('home')} href="#home">
              <p>Home</p>
            </a>
            <a onClick={() => scrollToSection('aboutus')} href="#aboutus">
              <p>About Us</p>
            </a>
            <a onClick={() => scrollToSection('services')} href="#services">
              <p>Services</p>
            </a>
            <a onClick={() => scrollToSection('portfolio')} href="#portfolio">
              <p>Portfolio</p>
            </a>
            <a href="#careers" >
          <p onClick={toggleModal}>Careers</p>
        </a>

          </div>
          {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>&times;</span>
            <h2>Careers</h2>
            <p>
              To apply for a job at Calcutta Constructions, please send your resume and cover letter to
              
            </p>
            <p><FiMail style={{ color: 'blue' }} size={15} /> <a class="apply" href="mailto:info@calcuttaconstructions.ca">info@calcuttaconstructions.ca</a>
</p>
          </div>
        </div>
      )}

          <div className='sb__footer-links_div1'>
            <h4>Contact Us</h4>
            <p><FaMapMarkerAlt /> <a className='footer_yellow' href="https://www.google.com/maps?q=12777+67a+Ave,+Surrey,+BC+V3W+1L9" target="_blank" rel="noopener noreferrer">12777 67a Ave, Surrey, BC V3W 1L9</a></p>
            <p><FiMail  /> <a className='footer_yellow' href="mailto:info@calcuttaconstructions.ca">info@calcuttaconstructions.ca</a></p>
          </div>
        </div>
      </div>

      <div className='sb__footer-below'>
        <div className='sb__footer-copyright' >
          <p>&copy; {new Date().getFullYear()}  Calcutta Constructions. All Rights Reserved</p>
        </div>
        <div className='sb__footer-below-links'>
          <a href="#terms"><div><p>Terms & Conditions</p></div></a>
        </div>
        <ScrollToTopButton />
      </div>
    </div>
  )
}

export default Footer;
