import React from 'react'
import cer from '../assets/cer.jpeg'
import phil from '../assets/phil.jpeg'
import team from '../assets/team.jpeg'
import NumberAnimation from './NumberAnimation';
import './Record.scss'
function Record(){

  

  return (
    <div className='record'>
      <div className='record-container'>
        <h1>Why choose us?</h1>
        <p className='choose_para'>Experience and Expertise: Your Trusted Partners for Every Building Project</p>
        <div className='record-container2'>
          <div className='record-sub'>
          <div className='record-icon'>
            <img className='record-img' src={team} alt="" />
            </div>
            <h3 className='record-subhead'>OUR TEAM</h3>
            <p className='record-para'>Our skilled and certified craftsmen are dedicated to timely project completion and customer satisfaction. We exclusively entrust every task to seasoned professionals, ensuring exceptional results</p>
            <div class="go-corner">
        <div class="go-arrow">→</div>
      </div>
          </div>
         <div className='record-sub'>
         <div className='record-icon'>
         <img className='record-img' src={phil} alt="" />
         </div>
         <h3 className='record-subhead'>OUR PHILOSOPHY</h3>
         <p className='record-para'>We are committed to delivering exceptional service to our clients, upholding high standards of professionalism, positive engagement, and superior project management. Our mission is to ensure utmost customer satisfaction.</p>
         <div class="go-corner">
        <div class="go-arrow">→</div>
      </div>
         </div>
         <div className='record-sub'>
         <div className='record-icon'>
         <img className='record-img' src={cer} alt="" />
         </div>
         <h3 className='record-subhead'>OUR QUALITY</h3>
         <p className='record-para'>Our excellence is rooted in the dedication of our tradesmen, estimators, and management. They share a deep commitment to delivering quality craftsmanship and working collaboratively to achieve outstanding results.</p>
         <div class="go-corner">
        <div class="go-arrow">→</div>
      </div>
         </div>
        </div>
        <div className="record-animation">
          <div>
          <NumberAnimation targetNumber={251} />
      <span className='record-span'>Clients</span>
      <NumberAnimation targetNumber={251} />
      <span className='record-span'>Happy Clients</span>
          </div>
 
      
      
    </div>
      </div>
    
    
    </div>
  )
}

export default Record