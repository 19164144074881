import React, { Component } from 'react';
import "./NumberAnimation.scss"
class NumberAnimation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: 0, // Initial number
      targetNumber: this.props.targetNumber, // Target number to reach
      speed: this.props.speed || 1,
    };
  }

  componentDidMount() {
    // Start the number animation when the component mounts
    this.animateNumbers();
  }

  animateNumbers() {
    const { targetNumber } = this.state;
    let currentNumber = this.state.number;

    
    const intervalId = setInterval(() => {
      if (currentNumber < targetNumber) {
        currentNumber += 1;
        this.setState({ number: currentNumber });
      } else {
        clearInterval(intervalId);
      }
    }, 100);
  }

  render() {
    return <div className="number-animation">{this.state.number}</div>;
  }
}

export default NumberAnimation;
