import React, { useState, useRef } from 'react';
import './Quote.scss';
import emailjs from '@emailjs/browser';

const emailRegex = /^\S+@\S+\.\S+$/;
const phoneRegex = /^\d{10}$/;

function Quote() {

  const form = useRef();

  const sendEmail = () => {
  

    emailjs.sendForm('service_t4dtib6', 'template_qikatbd', form.current, 'mY9vTS709tVTKtTmP')
      .then((result) => {
          console.log(result.text);
          window.alert('Request sent! Please wait for a reply on your email. Thanks.');
          window.location.reload();
      }, (error) => {
          console.log(error.text);
      });
     
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!emailRegex.test(formData.email)) {
      window.alert('Invalid email format. Please enter a valid email.');
      return;
    }

    if (!phoneRegex.test(formData.phone)) {
      window.alert('Invalid phone number. Please enter a 10-digit phone number.');
      return;
    }
    sendEmail();

  };
  
 

  

  
  return (
    <>
      <div className="quote1-container">
      
        <div className="quote1-second">
        <div className="quote1-knowme">
              <div className="quote1-knowme__header">
              <h1 className="quote1-header">Get a FREE Quote for your next project.</h1>
             </div>
             <p className="quote1-knowme__txt">In the building trades, excellence in quality, time management, and staying within budget are fundamental pillars of every successful project</p>
             <p className="quote1-knowme__txt">
             At <span className="bold">Calcutta Construction</span>
             , our dedicated management and skilled work crews have consistently embraced these principles in every endeavor. Whether it's a <span className="bold">Drywall, Taping, Steel Stud, or Renovation</span>Projects, we are committed to delivering outstanding results. Contact us today for a competitive quote and experience the difference that quality and precision can make.
                </p>

            
</div>

<div className='quote-container'>
      <h2 className='quote-heading'>GET A QUOTE</h2>
      <p className='quote-para'>Feel free to ask any questions</p>

      <form ref={form} onSubmit={handleSubmit}>
        <div className="quote-form_group">
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            required
            className='quote-input'
          />
        </div>

        <div className="quote-form_group">
      
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
            className='quote-input'
          />
        </div>

        <div className="quote-form_group">
         
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone"
            required
            className='quote-input'
          />
        </div>

        <div className="quote-form_group">
          
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
            required
            className='quote-input'
            rows="6" 
          />
        </div>

        <button className='quote-btn' value="send" type="submit">Get a quote</button>
      </form>
    </div>
        </div>
      </div>
     
    </>
  );
}

export default Quote;
