
import { useState } from 'react';
import { ReactComponent as Hamburger } from "../assets/menu-hamburger.svg";
import iconportfolio from "../assets/logo1.png"
import './Navhead.scss';
import Top from './Top'
function NavHead() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: 'smooth' });
    setActive(sectionId);
  };

  const [showNavbar, setShowNavbar] = useState(false);
  const [active, setActive]= useState ('');
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  

  return (
    <nav className="navbar-body">
      <Top/>
      <div className="navbar-container">
       <div className="nav-logo" onClick={() => scrollToSection('home')}>
          <img className='nav-iconport'  src={iconportfolio} alt="iconport" />
          
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          {showNavbar ? (
            <div className="exit-button" onClick={handleShowNavbar}>
              X
            </div>
          ) : (
            <Hamburger />
          )}
        </div>
        <div className='nav-desktop'>
    <ul className='nav-desktopul'>
      <li
        className={active === 'home' ? 'active' : ''}
        onClick={() => scrollToSection('home')}
      >
        <span>Home</span>
      </li>
      <li
        className={active === 'aboutus' ? 'active' : ''}
        onClick={() => scrollToSection('aboutus')}
      >
        <span>About Us</span>
      </li>
      <li
        className={active === 'services' ? 'active' : ''}
        onClick={() => scrollToSection('services')}
      >
        <span>Services</span>
      </li>
      <li
        className={active === 'portfolio' ? 'active' : ''}
        onClick={() => scrollToSection('portfolio')}
      >
        <span>Portfolio</span>
      </li>
      <li
        className={active === 'contact' ? 'active' : ''}
        onClick={() => scrollToSection('contact')}
      >
        <span>Contact</span>
      </li>
    </ul>
    <style>
      {`
      .nav-desktopul li.active span {
        text-decoration: underline;
      }
      `}
    </style>
  </div>
      </div>
     
      {showNavbar && (
        <div className="nav-overlay">
          <div className="nav-elements">
            <ul>
              <li onClick={() => scrollToSection('home')}>
                <span>HOME</span>
              </li>
              <li onClick={() => scrollToSection('aboutus')}>
                <span>ABOUT US</span>
              </li>
              <li onClick={() => scrollToSection('services')}>
                <span>SERVICES</span>
              </li>
              <li onClick={() => scrollToSection('portfolio')}>
                <span>PORTFOLIO</span>
              </li>
              <li onClick={() => scrollToSection('contact')}>
                <span>CONTACT</span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
}

export default NavHead;
