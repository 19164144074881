import React from 'react';
import './Maps.scss'
function Maps() {
  return (
    <>
      <div className='map-section'>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2610.9664588358546!2d-122.87117078772968!3d49.12527217125015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485dbc4a8102f9b%3A0x6f9ec8546292d9b1!2s12777%2067a%20Ave%2C%20Surrey%2C%20BC%20V3W%201L9!5e0!3m2!1sen!2sca!4v1695844212947!5m2!1sen!2sca"
          title="Google Map of Calcutta Constructions"
          width="100%"
          height="450"
          style={{ border: '0' }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
}

export default Maps;
