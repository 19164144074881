import React from 'react';
import './CareerPage.scss';
import './Navhead';
import NavHead from './Navhead';
import Footer from './Footer';
import sampleImage from  '../assets/blog.jpg'
import sampleImage1 from  '../assets/3792.jpg'
import sampleImage2 from  '../assets/image1.jpg'
import { FiMail } from 'react-icons/fi';

const CareerPage = () => {
  return (
    <>
    <NavHead/>
      <div className="career-page">
        <div className="career-header">
        <div className="career-intro">
            <h1>Careers at Calcutta Constructions</h1>
            <p>Join our team of dedicated professionals and make a difference in the construction industry.</p>
          </div>
          <img className="career-photo" src={sampleImage2} alt="career" />
          
        </div>

        {/* First section with text on the left and image on the right */}
        <div className="career-section">
          <div className="career-text">
            <h2>Why Choose Calcutta Constructions?</h2>
            <ul className="reasons-list">
              <li>- Competitive salaries</li>
              <li>- Collaborative and supportive work environment</li>
              <li>- Making a difference in the construction industry</li>
              <li>- Joining a team of experts in the field</li>
            </ul>
          </div>
          <div className="career-image">
            <img className='career-smallimages' src={sampleImage1} alt="career1" />
          </div>
        </div>

        {/* Second section with image on the left and text on the right */}
        <div className="career-section">
          <h1>Join Our Team</h1>
        <p>
     At Calcutta Constructions, we are on the lookout for dedicated and passionate individuals who are ready to make a meaningful impact in the construction industry. We value hard work, innovation, and a commitment to excellence. Our ideal candidates are team players who thrive in a collaborative and supportive work environment. We offer opportunities for professional growth and welcome individuals who are eager to learn and adapt to new challenges. If you are looking to join a team of experts, receive competitive compensation, and contribute to our mission of transforming the construction industry, we encourage you to explore our current job openings and consider a career with us
     </p>
          <div className="career-image">
            <img className='career-smallimages' src={sampleImage} alt="career2" />
          </div>
          <div className="career-text">
            <h2>How to Apply</h2>
            <p>
              To apply for a job at Calcutta Constructions, please send your resume and cover letter to
              
            </p>
         

<p><FiMail style={{ color: 'blue' }} size={15} /> <a class="apply" href="mailto:info@calcuttaconstructions.ca">info@calcuttaconstructions.ca</a>
</p>

          </div>
        </div>

        

      </div>
      <Footer/>
    </>
  );
};

export default CareerPage;