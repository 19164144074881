import React from 'react';
import './Services.scss';
import taping from "../assets/taping.png"
import demolition from "../assets/demolition.png"
import drywall from "../assets/drywall.png"
import steel from "../assets/steel.png"
import renovation from "../assets/renovation.png"
import tbar from '../assets/tbar.png'
import 'animate.css';

function Services() {
  const servicesData = [
    {
      icon: <img className='services-icon' src={drywall} alt="drywall" />,
      title: 'DRYWALL',
      description: 'Our drywall services provide the foundation for your walls and ceilings. We expertly install and finish drywall, ensuring a smooth and even surface ready for painting or further decoration.',
    },
    {
      icon: <img className='services-icon' src={steel} alt="steel" />,
      title: 'STEEL STUD FRAMING',
      description: 'Steel stud framing forms the structural skeleton of your building. We use durable steel framing to ensure the stability and longevity of your construction project.',
    },
    {
      icon: <img className='services-icon1' src={renovation} alt="renovation" />,
      title: 'RENOVATION',
      description: 'Transform your space with our renovation services. From updating interiors to complete makeovers, we breathe new life into residential and commercial properties.',
    },
    {
      icon: <img className='services-icon1' src={taping} alt="taping" />,
      title: 'TAPING',
      description: 'Taping is a crucial step in the drywall process. Our skilled professionals use precise techniques to tape and mud joints, creating seamless transitions between drywall sheets.',
    },
    {
      icon: <img className='services-icon1' src={tbar} alt="tbar" />,
      title: 'T BAR CEILING',
      description: 'T-Bar ceilings provide a clean and professional look. We design and install T-Bar ceilings for commercial spaces, ensuring an attractive and functional overhead environment.',
    },
     {
      
      icon: <img className='services-icon' src={demolition} alt="demolition" />,
      title: 'DEMOLITION',
      description: 'When its time to make way for something new, our demolition services ensure a safe and efficient removal of structures or materials. We clear the path for your construction or renovation project.',
    },
   
  ];

  return (
    <div className="services-container">
      <h1 className="services-heading">Services</h1>
      <p className="services-paragraph">
      Creating Smooth and Perfect Surfaces for Your Space
      </p>
      <div className="card-container ">
        {servicesData.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service-icon">{service.icon}</div>
            <h2>{service.title}</h2>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
