import React, { useState } from 'react';
import dry1 from '../assets/dry1.jpg'
import dry2 from '../assets/dry2.jpg'
import dry3 from '../assets/dry3.jpg'
import tape1 from '../assets/tape1.jpg'
import tape2 from '../assets/tape2.jpg'
import tape3 from '../assets/tape3.jpg'
import steel1 from '../assets/steel1.jpg'
import steel2 from '../assets/steel2.jpg'
import steel3 from '../assets/steel3.jpg'

import './Portfolio.scss';

const Portfolio = () => {
  // State to keep track of the selected filter option
  const [selectedOption, setSelectedOption] = useState('All');

  // Array of images with their categories
  const images = [
    { id: 1, src: dry1 , category: 'Drywall' },
    { id: 2, src: dry2 , category: 'Drywall' },
    { id: 3, src: dry3 , category: 'Drywall' },
    { id: 4, src: tape1, category: 'Taping' },
    { id: 5, src: tape2, category: 'Taping' },
    { id: 6, src: tape3, category: 'Taping' },
    { id: 7, src: steel1, category: 'Steel' },
    { id: 8, src: steel2, category: 'Steel' },
    { id: 9, src: steel3, category: 'Steel' },

    // Add more images with their respective categories here
  ];

  // Function to filter images based on the selected option
  const filteredImages = selectedOption === 'All' ? images : images.filter(image => image.category === selectedOption);

  return (
    <div className="portfolio">
      <h1>Portfolio</h1>
      
      {/* Filter buttons */}
      <div className="filter-options">
        <button className='port-btn' onClick={() => setSelectedOption('All')}>All</button>
        <button className='port-btn' onClick={() => setSelectedOption('Drywall')}>Drywall</button>
        <button className='port-btn' onClick={() => setSelectedOption('Taping')}>Taping</button>
        <button className='port-btn' onClick={() => setSelectedOption('Steel')}>Steel Stud</button>
      </div>

      {/* Display images */}
      <div className="image-grid">
        {filteredImages.map(image => (
          <div key={image.id} className="image-item">
            <img src={image.src} alt={` ${image.id}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
